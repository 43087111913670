<template>
  <b-row>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="campaign name"
        rules="required|max:100"
      >
        <HeroInputText
          id="campaign-name"
          v-model="campaign.name"
          label="Campaign Name"
          :required="true"
          :readonly="isCampaignNameDisable"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="campaign status"
        rules="required"
      >
        <HeroVueSelect
          id="status"
          v-model="campaign.campaign_status_id"
          label="Campaign Status"
          :required="true"
          :clearable="false"
          :options="campaignStatusOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="business objectives"
        rules="required"
      >
        <HeroVueSelect
          id="status"
          v-model="campaign.business_objectives"
          label="Business Objectives"
          :required="true"
          :clearable="false"
          :options="businessObjectiveOption"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <ValidationProvider
        v-slot="{ errors }"
        name="brief_date"
        class="form-group"
        tag="div"
        rules="beforestartdate:@campaign start date"
      >
        <HeroInputDate
          id="brief-date"
          v-model="campaign.brief_date"
          name="brief_date"
          label="Brief Date"
          :add-calendar="true"
          :readonly="true"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </ValidationProvider>
    </b-col>
    <b-col cols="12" md="4">
      <ValidationProvider
        v-slot="{ errors }"
        name="campaign start date"
        class="form-group"
        tag="div"
        rules="required|afterstartdate:@brief_date|before:@campaign end date"
      >
        <HeroInputDate
          id="start-date"
          v-model="campaign.actual_campaign_start_date"
          name="campaign start date"
          label="Campaign Start Date"
          :add-calendar="true"
          :required="true"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </ValidationProvider>
    </b-col>
    <b-col cols="12" md="4">
      <ValidationProvider
        v-slot="{ errors }"
        name="campaign end date"
        class="form-group"
        tag="div"
        rules="required|after:@campaign start date"
      >
        <HeroInputDate
          id="end-date"
          v-model="campaign.actual_campaign_end_date"
          label="Campaign End Date"
          :add-calendar="true"
          :required="true"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          inputclass="form-control"
        />
      </ValidationProvider>
    </b-col>
    <b-col cols="12" md="4">
      <ValidationProvider
        v-slot="{ errors }"
        name="paused_date"
        class="form-group"
        tag="div"
        rules="afterdate:@campaign start date|beforedate:@campaign end date"
      >
        <HeroInputDate
          id="pause-date"
          v-model="campaign.paused_date"
          label="Campaign Pause Date"
          :add-calendar="true"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </ValidationProvider>
    </b-col>
    <b-col cols="12" md="4">
      <ValidationProvider
        v-slot="{ errors }"
        name="unpaused_date"
        class="form-group"
        tag="div"
        rules="afterpause:@paused_date|beforeend:@campaign end date"
      >
        <HeroInputDate
          id="unpause-date"
          v-model="campaign.unpaused_date"
          label="Campaign Restart Date"
          :add-calendar="true"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </ValidationProvider>
    </b-col>
    <b-col cols="12" md="4">
      <HeroInputText
        id="churn-date"
        :value="getDate(campaign.churn_date)"
        label="Churn Date"
        :readonly="true"
      />
    </b-col>
    <b-col cols="12" md="4">
      <HeroInputText
        id="churn-cycle"
        :value="campaign.churn_cycle"
        label="Churn Cycle"
        :readonly="true"
      />
    </b-col>
    <b-col cols="12" md="4">
      <HeroInputText
        id="google-account-id"
        :value="getGoogleAccIds"
        label="Google Account ID"
        :add-more="true"
        :readonly="true"
        @addMore="$bvModal.show('google-account-id')"
      />
      <AddDataList
        id="google-account-id"
        title="Enter Google Account ID"
        label="Google Account ID"
        rules="required"
        :initial-data-list="campaign.google_account_id"
        @success="campaign.google_account_id = [...$event]"
      />
    </b-col>
    <b-col cols="12" md="4">
      <HeroInputText
        id="facebook-account-id"
        :value="getFacebookAccIds"
        label="Facebook Account ID"
        :add-more="true"
        :readonly="true"
        @addMore="$bvModal.show('facebook-account-id')"
      />
      <AddDataList
        id="facebook-account-id"
        title="Enter Facebook Account ID"
        label="Facebook Account ID"
        rules="required"
        :initial-data-list="campaign.facebook_account_id"
        @success="campaign.facebook_account_id = [...$event]"
      />
    </b-col>
    <b-col cols="12" md="4">
      <HeroInputText
        id="tiktok-account-id"
        :value="getTikTokAccIds"
        label="TikTok Account ID"
        :add-more="true"
        :readonly="true"
        @addMore="$bvModal.show('tiktok-account-id')"
      />
      <AddDataList
        id="tiktok-account-id"
        title="Enter TikTok Account ID"
        label="TikTok Account ID"
        rules="required"
        :initial-data-list="campaign.tiktok_account_id"
        @success="campaign.tiktok_account_id = [...$event]"
      />
    </b-col>
    <b-col v-if="isEditMode" cols="12" md="4">
      <HeroInputText
        id="bonus"
        v-model="campaign.bonus"
        label="Bonus/Discount"
      />
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="GA property Id"
        rules="max:255|validgaproperty"
      >
        <HeroInputText
          id="ga-property-id"
          v-model="campaign.ga_property_id"
          label="GA Property ID"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="GA profile Id"
        rules="max:255|validgaprofile"
      >
        <HeroInputText
          id="ga-profile-id"
          v-model="campaign.ga_profile_id"
          label="GA Profile ID"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="client website"
        rules="url"
      >
        <HeroInputText
          id="client-website"
          v-model="campaign.client_website"
          label="Client Website"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <HeroVueSelect
        id="client_at_risk_stage"
        v-model="campaign.client_at_risk_stage"
        label="Client At Risk Stage"
        :options="clientAtRiskOptions"
        :clearable="false"
      />
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="reason for high risk"
        rules="max:100"
      >
        <HeroInputText
          id="reason_for_high_risk"
          v-model="campaign.reason_for_high_risk"
          label="Reason For High Risk"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <HeroInputText
        id="hashtags"
        :value="campaign.hashtags.join(', ')"
        label="Hashtags"
        :add-more="true"
        :readonly="true"
        @addMore="$bvModal.show('hashtags')"
      />
      <AddDataList
        id="hashtags"
        title="Enter Hashtags"
        label="Hashtags"
        rules="required"
        :initial-data-list="campaign.hashtags"
        @success="campaign.hashtags = [...$event]"
      />
    </b-col>
    <b-col cols="12" md="4">
      <HeroCheckbox
        id="demo-campaign"
        v-model="campaign.demo_campaign"
        label="Demo Campaign"
      />
    </b-col>
    <b-col cols="12" md="4">
      <HeroCheckbox
        id="is-no-sync-campaign"
        v-model="campaign.is_no_sync_campaign"
        label="No Sync Campaign"
      />
    </b-col>
  </b-row>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputDate from '@/views/components/form/inputs/HeroInputDate.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import Store from '@/store/index'
import { extend } from 'vee-validate'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import CampaignOverview from '@/models/Campaigns/CampaignOverview'
import DataFormatService from '@/services/DataFormatService'
import AddDataList from '@/views/heroai/campaigns/components/create/AddDataList.vue'

extend('after', {
  params: ['target'],
  validate(value, { target }) {
    const startdate = new Date(target)
    const enddate = new Date(value)
    return startdate.getTime() <= enddate.getTime()
  },
  message: 'End date should be greater than or equal to start date',
})

extend('before', {
  params: ['target'],
  validate(value, { target }) {
    const startdate = new Date(value)
    const enddate = new Date(target)
    return startdate.getTime() <= enddate.getTime()
  },
  message: 'Start date should be less than or equal to end date',
})

extend('afterstartdate', {
  params: ['target'],
  validate(value, { target }) {
    if (target) {
      const briefdate = new Date(target)
      const startdate = new Date(value)
      return briefdate.getTime() <= startdate.getTime()
    }
    return true
  },
  message: 'Start date should be greater than or equal to brief date',
})

extend('beforestartdate', {
  params: ['target'],
  validate(value, { target }) {
    if (value) {
      const briefdate = new Date(value)
      const startdate = new Date(target)
      return briefdate.getTime() < startdate.getTime()
    }
    return true
  },
  message: 'Brief date should be less than start date',
})

extend('afterdate', {
  params: ['target'],
  validate(value, { target }) {
    if (value) {
      const pausedate = new Date(target)
      const startdate = new Date(value)
      return pausedate.getTime() < startdate.getTime()
    }
    return true
  },
  message: 'Pause date should be greater than start date',
})

extend('beforedate', {
  params: ['target'],
  validate(value, { target }) {
    if (value) {
      const pausedate = new Date(value)
      const enddate = new Date(target)
      return pausedate.getTime() < enddate.getTime()
    }
    return true
  },
  message: 'Pause date should be less than end date',
})

extend('afterpause', {
  params: ['target'],
  validate(value, { target }) {
    if (value) {
      const unpausedate = new Date(target)
      const pausedate = new Date(value)
      return unpausedate.getTime() < pausedate.getTime()
    }
    return true
  },
  message: 'Restart date should be greater than pause date',
})

extend('beforeend', {
  params: ['target'],
  validate(value, { target }) {
    if (value) {
      const unpausedate = new Date(value)
      const enddate = new Date(target)
      return unpausedate.getTime() < enddate.getTime()
    }
    return true
  },
  message: 'Restart date should be less than end date',
})

extend('validgaproperty', {
  params: ['target'],
  validate(target) {
    if (target) {
      const Pattern1 = /^[A-Z]{2,}-\d+-\d+$/g
      const Pattern2 = /^[G]{1}-\w{10}$/g

      if (Pattern1.test(target) || Pattern2.test(target)) {
        return true
      }
      return false
    }
    return true
  },
  message: 'Please Enter Google Analytics Property ID match format (ex: UA-123456-12, G-0123456789).',
})

extend('validgaprofile', {
  params: ['target'],
  validate(target) {
    if (target) {
      const Pattern1 = /^([\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/

      if (Pattern1.test(target)) {
        return true
      }
      return false
    }
    return true
  },
  message: 'Please Enter Google Analytics Profile ID with Number format.',
})

export default {
  name: 'CampaignOverview',
  components: {
    AddDataList,
    HeroInputText,
    HeroInputDate,
    HeroVueSelect,
    HeroCheckbox,
  },
  props: {
    campaign: {
      type: Object,
      require: true,
      default: () => new CampaignOverview({}),
    },
    isEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    campaignStatusOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    businessObjectiveOption: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
  },
  data() {
    return {
      clientAtRiskOptions: [
        {
          text: 'None',
          value: 'None',
        },
        {
          text: '0% High Risk',
          value: '0% High Risk',
        },
        {
          text: '25% Risk',
          value: '25% Risk',
        },
        {
          text: '50% Neutral',
          value: '50% Neutral',
        },
        {
          text: '75% Satisfied',
          value: '75% Satisfied',
        },
        {
          text: '100% Happy',
          value: '100% Happy',
        },
        {
          text: 'Covid Impact',
          value: 'Covid Impact',
        },
      ],
    }
  },
  computed: {
    getGoogleAccIds() {
      if (this.campaign.google_account_id && this.campaign.google_account_id.length > 0) {
        return this.campaign.google_account_id.join(', ')
      }
      return ''
    },
    getFacebookAccIds() {
      if (this.campaign.facebook_account_id && this.campaign.facebook_account_id.length > 0) {
        return this.campaign.facebook_account_id.join(', ')
      }
      return ''
    },
    getTikTokAccIds() {
      if (this.campaign.tiktok_account_id && this.campaign.tiktok_account_id.length > 0) {
        return this.campaign.tiktok_account_id.join(', ')
      }
      return ''
    },
    isCampaignNameDisable() {
      return this.$route.name === 'retain-campaign'
    },
  },
  watch: {
    campaign: {
      handler(newValue) {
        Store.commit('heroAiCampaign/setCampaign', newValue)
      },
      deep: true,
    },
  },
  methods: {
    getDate(value) {
      const date = DataFormatService.formatUsaDate(value)
      if (date) {
        return date
      }
      return ''
    },
  },
}
</script>
