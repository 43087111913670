import ErrorHandler from '@/libs/ErrorHandler'
import axiosInstance from '@/libs/axiosInstance'

class Account {
  static async get(url, qparam, isUseErrorHandler = true) {
    try {
      const response = await axiosInstance(
        url, {
          method: 'get',
          params: qparam,
        },
      )
      return response.data
    } catch (error) {
      if (isUseErrorHandler) {
        return new ErrorHandler(error)
      }
      return Promise.reject(error)
    }
  }

  static async post(url, qparam, data) {
    try {
      const response = await axiosInstance(
        url, {
          method: 'post',
          params: qparam,
          data,
        },
      )
      return response.data
    } catch (error) {
      return new ErrorHandler(error)
    }
  }

  static async patch(url, qparam, data) {
    try {
      const response = await axiosInstance(
        url, {
          method: 'patch',
          params: qparam,
          data,
        },
      )
      return response.data
    } catch (error) {
      return new ErrorHandler(error)
    }
  }

  static async delete(url) {
    try {
      const response = await axiosInstance(
        url, {
          method: 'delete',
        },
      )
      return response.data
    } catch (error) {
      return new ErrorHandler(error)
    }
  }
}

export default Account
